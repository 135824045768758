import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Badge, CircularProgress } from '@mui/material';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import { Avatar, HorizontalLine, MultiLineText } from 'Components';
import {
  StreamItemInTimeline,
  StreamInfoDataType,
  MemberInfoDataType,
  StreamAction,
  MemberInfo,
  MemberFullInfo,
  CommentBox,
  AttachedFile,
  ChatInStream,
} from './Components';
import styles from '../stream.module.css';
import { ResponseDataType, getRequest } from 'api';
import moment from 'moment';
import { useAppContext } from 'Context/AppContext';
import { StreamDetailProvider } from './Context/StreamDetailContext';
import { CommentOutlined } from '@mui/icons-material';
import styled from '@emotion/styled';

interface StreamDetailDataType {
  creator: MemberInfoDataType;
  description: string;
  createAt: string;
  streamList: StreamInfoDataType[];
  isEnd: number;
  numberOfNewMsg: number;
}

export const StreamDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentOrg, onEvent } = useAppContext();
  const { streamId } = useParams();
  const [streamDetail, setStreamDetail] = useState<StreamDetailDataType>();
  const [selectedStream, setSelectedStream] = useState<StreamInfoDataType>();
  const [showChat, setShowChat] = useState<boolean>(false);
  const [numberOfNewMsg, setNumberOfNewMsg] = useState<number>(0);

  const GetStreamDetail = async (isFirstTime: boolean) => {
    const response: ResponseDataType<StreamDetailDataType> = await getRequest(
      `/stream/${currentOrg}/stream-detail/${streamId}`
    );

    if (response.code) {
      toast.error(response.message);
      navigate(
        location.pathname.includes('proposal-detail')
          ? '/proposal-list'
          : '/request-list'
      );
    } else {
      setStreamDetail(response.data);
      setNumberOfNewMsg(response.data?.numberOfNewMsg || 0);
      if (isFirstTime) {
        const notiType = searchParams.get('type');
        const relateId = searchParams.get('relateId');
        if (
          relateId &&
          notiType &&
          ['2', '3', '4', '5', '7'].includes(notiType)
        ) {
          setSelectedStream(
            response.data?.streamList.find(
              (stream) => stream.id === relateId
            ) || response.data?.streamList[0]
          );
          searchParams.delete('type');
          searchParams.delete('relateId');
          setSearchParams(searchParams);
        } else setSelectedStream(response.data?.streamList[0]);

        if (searchParams.get('showChat') === 'true') {
          setNumberOfNewMsg(0);
          setShowChat(true);
        }
      } else {
        setSelectedStream((prev) =>
          response.data?.streamList.find((stream) => stream.id === prev?.id)
        );
      }
    }
  };

  useEffect(() => {
    GetStreamDetail(true);
    setShowChat(false);
    // eslint-disable-next-line
  }, [streamId, navigate]);

  useEffect(() => {
    onEvent('update-stream', (updateStreamId) => {
      if (updateStreamId === streamId) {
        GetStreamDetail(false);
      }
    });
    // eslint-disable-next-line
  }, [onEvent, streamId, navigate]);

  useEffect(() => {
    if (!showChat) {
      onEvent('new-stream-chat', (res: { rootId: string }) => {
        const newChatStreamRootId = res.rootId;
        if (streamId === newChatStreamRootId && !showChat) {
          setNumberOfNewMsg((prev) => prev + 1);
        }
      });
    }
  }, [onEvent, streamId, setNumberOfNewMsg, showChat]);

  const toggleChat = () => {
    if (!showChat) setNumberOfNewMsg(0);
    setShowChat((prev) => !prev);
  };

  return (
    <div className={styles.stream_detail_wrapper}>
      {streamDetail ? (
        <Row className={styles.stream_detail} justify='space-between'>
          <Col span={10}>
            <Row justify='space-between' align='middle'>
              <Col span={22}>
                <Row>
                  <Avatar
                    src={streamDetail.creator.avatar}
                    className={styles.creator_avatar}
                  />
                  <div>
                    <div className={styles.creator_name}>
                      <MemberInfo {...streamDetail.creator} />
                    </div>
                    <div style={{ fontSize: 12 }}>
                      <span style={{ fontWeight: 700 }}>Ngày tạo: </span>
                      <span>
                        {moment(streamDetail.createAt).format(
                          'HH:mm DD/MM/YYYY'
                        )}
                      </span>
                    </div>
                  </div>
                </Row>
              </Col>
              <Col span={2}>
                <OpenChatWrapper onClick={toggleChat}>
                  <NewChatNotiNumber
                    badgeContent={numberOfNewMsg}
                    color='error'
                  >
                    <CommentOutlined
                      className='abcdefok'
                      sx={{ color: 'white', fontSize: 25 }}
                    />
                  </NewChatNotiNumber>
                </OpenChatWrapper>
              </Col>
            </Row>
            <HorizontalLine style={{ margin: '15px 0' }} />
            <div className={styles.content_label}>Nội dung</div>
            <div style={{ fontWeight: 700 }}>{streamDetail.description}</div>
            <HorizontalLine style={{ margin: '15px 0' }} />
            <div className={styles.content_label}>Tiến trình xử lý</div>
            {streamDetail.streamList.map((streamItem, index) => (
              <StreamItemInTimeline
                key={index}
                isSelected={streamItem.id === selectedStream?.id}
                isTheLast={index === streamDetail.streamList.length - 1}
                select={() => {
                  setSelectedStream(streamItem);
                  setShowChat(false);
                }}
                {...streamItem}
              />
            ))}
          </Col>
          {showChat && <ChatInStream rootId={streamId || ''} />}
          <Col span={12} style={{ display: showChat ? 'none' : 'block' }}>
            <div className={styles.content_label}>Thông tin chi tiết</div>
            <div className={styles.selected_stream_label}>Người thực hiện</div>
            <MemberFullInfo
              {...(selectedStream?.creator as MemberInfoDataType)}
            />
            <HorizontalLine style={{ margin: '15px 0' }} />
            <div className={styles.selected_stream_label}>Nội dung</div>
            <MultiLineText
              style={{ fontWeight: 700 }}
              text={
                selectedStream?.content || selectedStream?.description || ''
              }
            />
            <HorizontalLine style={{ margin: '15px 0' }} />
            {selectedStream && (
              <StreamDetailProvider
                isStreamFollowEnd={streamDetail.isEnd}
                selectedStreamId={selectedStream.id}
              >
                <StreamAction
                  streamId={selectedStream.id}
                  {...selectedStream}
                />
                <AttachedFile isAssignee={selectedStream.isAssignee} />
                <CommentBox />
              </StreamDetailProvider>
            )}
          </Col>
        </Row>
      ) : (
        <CircularProgress size={50} sx={{ color: '#141d49', marginTop: 10 }} />
      )}
    </div>
  );
};

const OpenChatWrapper = styled.div`
  background-color: #141d49;
  padding: 12px 11px 8px 11px;
  border-radius: 50%;
  width: max-content;
  cursor: pointer;
`;

const NewChatNotiNumber = styled(Badge)`
  .MuiBadge-badge {
    top: -9px;
    right: -9px;
  }
`;
