import { EndStreamType, NormalNotiType } from 'constant/enum';
import { ActionPropType, StreamActionPropType } from '..';
import { completeDynamicStream, updateApproveTaskValue } from './action';
import {
  ButtonBox,
  ButtonGreen,
  ButtonRed,
  DisableButtonGreenWrapper,
  DisableButtonRedWrapper,
  Space,
} from './style';
import { useAppContext } from 'Context/AppContext';
import { DepartmentNamecode } from 'constant/department';
import { Row } from 'antd';
import { useStreamDetailInfo } from 'Pages/Stream/StreamDetail/Context/StreamDetailContext';
import { useState } from 'react';
import { ContinueDynamicStreamPopup } from './ContinueDynamicStreamPopup';

const ApproveDenyAction = (props: ActionPropType) => {
  const { streamId } = props;
  const { currentOrg } = useAppContext();

  return (
    <ButtonBox>
      <ButtonGreen
        onClick={() =>
          updateApproveTaskValue({
            value: 1,
            streamId,
            endStream: false,
            title: 'Xác nhận duyệt',
            currentOrg,
          })
        }
      >
        Duyệt
      </ButtonGreen>
      <Space />
      <ButtonRed
        onClick={() =>
          updateApproveTaskValue({
            value: 0,
            streamId,
            endStream: true,
            title: 'Xác nhận từ chối',
            currentOrg,
            endType: EndStreamType.REFUSE,
          })
        }
      >
        Từ chối
      </ButtonRed>
    </ButtonBox>
  );
};

export const ApproveTaskAction = (props: StreamActionPropType) => {
  const { isEnd, canUpdate, optionValue, ...rest } = props;

  if (optionValue === 1) {
    return <DisableButtonGreenWrapper label='Đã duyệt' />;
  }

  if (optionValue === 0) {
    return <DisableButtonRedWrapper label='Đã từ chối' />;
  }

  if (!canUpdate) {
    return <DisableButtonGreenWrapper label='Chờ duyệt' />;
  }

  return <ApproveDenyAction {...rest} />;
};

const ConfirmAction = (props: ActionPropType) => {
  const { streamId } = props;
  const { currentOrg, currentOrgInfo } = useAppContext();

  return (
    <ButtonBox>
      <ButtonGreen
        onClick={() =>
          updateApproveTaskValue({
            value: 1,
            streamId,
            endStream: false,
            title: 'Xác nhận duyệt?',
            currentOrg,
          })
        }
      >
        Xác nhận
      </ButtonGreen>
      <Space />
      {currentOrgInfo?.memberDepartmentCode === DepartmentNamecode.CEO && (
        <ButtonRed
          onClick={() =>
            updateApproveTaskValue({
              value: 0,
              streamId,
              endStream: true,
              title: 'Xác nhận hủy',
              currentOrg,
              endType: EndStreamType.CANCEL,
            })
          }
        >
          Hủy
        </ButtonRed>
      )}
    </ButtonBox>
  );
};

export const ConfirmTaskAction = (props: StreamActionPropType) => {
  const { isEnd, canUpdate, optionValue, ...rest } = props;

  if (optionValue === 1) {
    return <DisableButtonGreenWrapper label='Đã duyệt' />;
  }

  if (optionValue === 0) {
    return <DisableButtonRedWrapper label='Đã hủy' />;
  }

  if (!canUpdate) {
    return <DisableButtonGreenWrapper label='Chờ duyệt' />;
  }

  return <ConfirmAction {...rest} />;
};

const CompleteOrEndDynamicStream = (props: ActionPropType) => {
  const { streamId } = props;
  const { currentOrg } = useAppContext();

  return (
    <ButtonBox>
      <ButtonGreen
        onClick={() =>
          updateApproveTaskValue({
            value: 1,
            streamId,
            endStream: false,
            title: 'Xác nhận hoàn thành',
            currentOrg,
            notiType: NormalNotiType.EXECUTE_STREAM,
            allowUpdateEndStreamTask: true,
          })
        }
      >
        Hoàn thành
      </ButtonGreen>
      <Space />
      <ButtonRed
        onClick={() =>
          updateApproveTaskValue({
            value: 0,
            streamId,
            endStream: true,
            title: 'Xác nhận kết thúc',
            currentOrg,
            endType: EndStreamType.REFUSE,
            allowUpdateEndStreamTask: true,
          })
        }
      >
        Kết thúc
      </ButtonRed>
    </ButtonBox>
  );
};

const ContinueButton = (props: ActionPropType) => {
  const { streamId } = props;
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <Space />
      <ButtonBox>
        <ButtonGreen onClick={() => setShowPopup(true)}>
          Tiếp tục luồng
        </ButtonGreen>
      </ButtonBox>
      {showPopup && (
        <ContinueDynamicStreamPopup
          streamId={streamId}
          close={() => setShowPopup(false)}
        />
      )}
    </>
  );
};

const EndButton = (props: ActionPropType) => {
  const { streamId } = props;
  const { currentOrg } = useAppContext();

  return (
    <>
      <Space />
      <ButtonBox>
        <ButtonRed
          onClick={() =>
            completeDynamicStream({
              streamId,
              title: 'Xác nhận kết thúc?',
              currentOrg,
            })
          }
        >
          Kết thúc
        </ButtonRed>
      </ButtonBox>
    </>
  );
};

export const DynamicStreamAction = (props: StreamActionPropType) => {
  const { isStreamFollowEnd } = useStreamDetailInfo();
  const {
    isEnd,
    canUpdate,
    optionValue,
    isAssignee,
    isTheLastStream,
    ...rest
  } = props;

  if (optionValue === 1) {
    return (
      <Row>
        <DisableButtonGreenWrapper label='Đã hoàn thành' />
        {isAssignee && isTheLastStream && !isStreamFollowEnd && (
          <>
            <ContinueButton {...rest} />
            <EndButton {...rest} />
          </>
        )}
      </Row>
    );
  }

  if (optionValue === 0) {
    return <DisableButtonRedWrapper label='Đã từ chối' />;
  }

  if (!canUpdate) {
    return <DisableButtonGreenWrapper label='Chờ xác nhận' />;
  }

  return <CompleteOrEndDynamicStream {...rest} />;
};
