import styled from '@emotion/styled';
import { Button } from 'antd';

const ButtonAction = styled(Button)`
  border-width: 0;
  padding: 20px 30px;
  font-weight: 700;
  font-size: 16px;
`;

export const ButtonGreen = styled(ButtonAction)`
  background-color: #42b814;
  color: #fff !important;

  &:hover {
    background-color: #42b814d0 !important;
  }
`;

export const ButtonRed = styled(ButtonAction)`
  background-color: #f4e5e5;
  color: #c34343 !important;

  &:hover {
    background-color: #f4d5d5 !important;
  }
`;
export const ButtonBox = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const Space = styled.div`
  width: 20px;
`;

export const DisableButtonGreen = styled(ButtonAction)`
  background-color: #cef1c0 !important;
  color: #fff !important;
`;

export const DisableButtonGreenWrapper = ({ label }: { label: string }) => {
  return (
    <ButtonBox>
      <DisableButtonGreen disabled>{label}</DisableButtonGreen>
    </ButtonBox>
  );
};

const DisableButtonRed = styled(ButtonAction)`
  background-color: #f4d5d5 !important;
  color: #c34343 !important;
`;

export const DisableButtonRedWrapper = ({ label }: { label: string }) => {
  return (
    <ButtonBox>
      <DisableButtonRed disabled>{label}</DisableButtonRed>
    </ButtonBox>
  );
};
