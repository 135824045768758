import styled from '@emotion/styled';
import { Button, Form, Input, Row, Select } from 'antd';
import { getRequest, postRequest, ResponseDataType } from 'api';
import { Avatar, Popup, popupStyles } from 'Components';
import { useAppContext } from 'Context/AppContext';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { modalConfirm } from 'utils';

interface ContinueDynamicStreamPopupPropsType {
  streamId: string;
  close: () => void;
}

interface MemberInfoInList {
  id: string;
  name: string;
  avatar: string;
}

export const ContinueDynamicStreamPopup = (
  props: ContinueDynamicStreamPopupPropsType
) => {
  const { close, streamId } = props;
  const [form] = Form.useForm();
  const [memberList, setMemberList] = useState<MemberInfoInList[]>([]);
  const { currentOrg } = useAppContext();

  useEffect(() => {
    const getMemberList = async () => {
      const response: ResponseDataType<{
        managerList: string[];
        memberList: MemberInfoInList[];
      }> = await getRequest(
        `/organization/get-org-member-list?orgId=${currentOrg}&type=all`
      );

      if (!response.data) {
        setMemberList([]);
        return;
      }

      const resData = response.data;
      const sortedMemberList = resData.memberList.reduce(
        (curList: MemberInfoInList[], member) => {
          if (resData.managerList.includes(member.id)) {
            curList.unshift(member);
          } else {
            curList.push(member);
          }
          return curList;
        },
        []
      );
      setMemberList(sortedMemberList);
    };

    getMemberList();
  }, [currentOrg]);

  const handleContinueDynamicStream = (value: {
    title: string;
    assignee: string;
  }) => {
    modalConfirm({
      title: 'Xác nhận tiếp tục luồng với thông tin vừa nhập?',
      async onOk() {
        const response: ResponseDataType<{ id: string }> = await postRequest(
          '/stream/continue-dynamic-stream',
          {
            organizationId: currentOrg,
            streamId,
            ...value,
          }
        );

        if (response.code) {
          if (typeof response.message === 'object') {
            response.message.forEach((message: string) => toast.error(message));
          } else toast.error(response.message);
        } else {
          toast.success('Tiếp tục luồng thành công');
          close();
        }
      },
    });
  };

  return (
    <Popup close={close}>
      <>
        <div className={popupStyles.popup_title}>Thêm nội dung</div>
        <Form form={form} onFinish={handleContinueDynamicStream}>
          <div className={popupStyles.form}>
            <Form.Item
              name='title'
              rules={[
                {
                  required: true,
                  message: 'Cần nhập Tiêu đề nhiệm vụ tiếp theo!',
                },
              ]}
              style={{ margin: 0 }}
            >
              <Input placeholder='Tiêu đề' className={popupStyles.form_input} />
            </Form.Item>
            <div className={popupStyles.line} />
            <Form.Item
              name='assignee'
              rules={[
                {
                  required: true,
                  message: 'Cần chọn người nhận nhiệm vụ!',
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <FormItemSelect
                placeholder='Người thực hiện'
                optionFilterProp='label'
                showSearch={true}
                style={{
                  marginBottom: 0,
                  width: 'calc(100% - 20px)',
                  marginLeft: 4,
                }}
              >
                {memberList.map((member, index) => (
                  <Select.Option
                    key={index}
                    value={member.id}
                    label={member.name}
                  >
                    <Row>
                      <Avatar
                        src={member.avatar}
                        style={{ width: 20, height: 20, borderRadius: '50%' }}
                      />
                      <div style={{ lineHeight: 'normal', marginLeft: 10 }}>
                        {member.name}
                      </div>
                    </Row>
                  </Select.Option>
                ))}
              </FormItemSelect>
            </Form.Item>
          </div>
          <div className={popupStyles.form_button_wrapper}>
            <Button
              type='primary'
              className={`${popupStyles.form_button} ${popupStyles.form_button_primary}`}
              htmlType='submit'
            >
              Tiếp tục luồng
            </Button>
            <Button
              type='primary'
              className={`${popupStyles.form_button} ${popupStyles.form_button_cancel}`}
              onClick={close}
            >
              Huỷ
            </Button>
          </div>
        </Form>
      </>
    </Popup>
  );
};

const FormItemSelect = styled(Select)`
  .ant-select-selector {
    border-width: 0 !important;
    padding: 0 4px !important;
  }
`;
